import React, { useState } from "react";
import { Question } from "common/types";
import { useUser, useCompanies, usePools, useAnswers } from "shared/hooks";
import { List, ListItem } from "@material-ui/core";
import { utils } from "common";

type Props = {
    question: Question;
};

const QuestionComp = (props: Props) => {
    const [answers, setAnswer] = useAnswers();
    const [user, setUser] = useUser();
    const [companies] = useCompanies();
    const [pools] = usePools();
    const [otherAnswers, setOtherAnswers] = useState(false);

    if (!user || !setUser || !answers || !setAnswer) return <></>;

    const pool = (pools || []).find(p => p.id === props.question.poolId);
    if (!pool) return <></>;

    const company = (companies || []).find(c => c.id === pool.companyId);
    if (!company) return <></>;

    const answer = answers.filter(a => a.userId === user.id).find(a => a.questionId === props.question.id) || {
        ...utils.getEmptyAnswer(),
        userId: user.id,
        questionId: props.question.id,
        createdAt: Date.now()
    };

    const questionAnswers = answers.filter(a => a.questionId === props.question.id);

    return (
        <div style={{ backgroundColor: "#fff", marginBottom: 25, padding: 10 }}>
            <div style={{ display: "flex", flexDirection: "row", height: 40 }}>
                <div
                    style={{
                        width: 40,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                    <img src={company.logoUrl} alt="logo" height={"80%"} />
                </div>
                <div
                    style={{
                        display: "flex",
                        marginLeft: 24,
                        flexDirection: "column",
                        flex: 1
                    }}>
                    <div
                        style={{
                            display: "flex",
                            flex: 1,
                            justifyContent: "center",
                            flexDirection: "column"
                        }}>
                        <p style={{ fontWeight: 600, fontSize: 15, margin: 0 }}>{company.title}</p>
                        <p style={{ margin: 0, color: "#606060" }}>{pool.title}</p>
                    </div>
                </div>
            </div>
            <p style={{ fontSize: 16, margin: 0, marginTop: 10, marginBottom: 10 }}>{props.question.title}</p>
            <List>
                {props.question.options.map(option => {
                    const frac =
                        questionAnswers.filter(a => a.answers.includes(option)).length / questionAnswers.length || 0;
                    return (
                        <ListItem
                            key={option}
                            button
                            style={{
                                border: answer.answers.includes(option) ? "1px solid #3f51b5" : "1px solid #d8d8d8",
                                marginBottom: 10,
                                padding: 1,
                                display: "flex"
                            }}
                            onClick={() => {
                                if (!user.poolIds.includes(props.question.poolId))
                                    setUser({
                                        poolIds: [...user.poolIds, props.question.poolId]
                                    });
                                setAnswer({
                                    ...answer,
                                    answers: answer.answers.includes(option)
                                        ? answer.answers.filter(a => a !== option)
                                        : [...answer.answers, option]
                                });
                            }}>
                            <div
                                style={{
                                    display: "grid",
                                    flex: "1"
                                }}>
                                <div
                                    style={{
                                        width: `${otherAnswers ? Math.round(100 * frac) : 100}%`,
                                        padding: 4,
                                        backgroundColor:
                                            answer.answers.length > 0
                                                ? answer.answers.includes(option)
                                                    ? "rgba(63, 81, 181,0.3)"
                                                    : otherAnswers
                                                    ? "#dddddd"
                                                    : "#fff"
                                                : "transparent",
                                        gridColumn: 1,
                                        gridRow: 1
                                    }}>
                                    &nbsp;
                                </div>
                                <div
                                    style={{
                                        gridColumn: 1,
                                        gridRow: 1,
                                        display: "flex",
                                        alignItems: "center",
                                        paddingLeft: 5
                                    }}>
                                    {option}
                                </div>
                            </div>
                            {answer.answers.length > 0 && otherAnswers && (
                                <div
                                    style={{
                                        position: "absolute",
                                        right: 5,
                                        color: answer.answers.includes(option) ? "#3f51b5" : "#606060"
                                    }}>
                                    {Math.round(100 * frac)}%
                                </div>
                            )}
                        </ListItem>
                    );
                })}
            </List>
            {answer.answers.length > 0 && (
                <div
                    style={{
                        fontSize: 12,
                        textDecoration: "underline",
                        color: "rgb(63, 81, 181)",
                        cursor: "pointer"
                    }}
                    onClick={() => setOtherAnswers(!otherAnswers)}>
                    {otherAnswers ? "Skjul" : "Se"} andres svar
                </div>
            )}
        </div>
    );
};
export default QuestionComp;

import React, { useState, useEffect, createContext, useContext } from "react";
import { db } from "shared/firebase";
import { User } from "common/types";
import { getEmptyUser } from "common/utils";

const UserContext = createContext<[User | undefined, ((user: Partial<User>) => void) | undefined]>([
    undefined,
    undefined
]);

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }: { children: JSX.Element }) => {
    const [userId, setUserId] = useState<string | undefined>(undefined);
    const [user, setUser] = useState<User | undefined>(undefined);

    useEffect(() => {
        (async () => {
            let uid = window.localStorage.getItem("userId");
            if (!uid) {
                uid = db.collection("tmp").doc().id;
                window.localStorage.setItem("userId", uid);
            }
            const doc = await db.collection("users").doc(uid).get();
            if (!doc.exists) db.collection("users").doc(uid).set(getEmptyUser());
            setUserId(uid);
        })();
    }, []);

    useEffect(() => {
        if (!userId) return undefined;
        return db
            .collection("users")
            .doc(userId)
            .onSnapshot(doc => setUser({ ...getEmptyUser(), ...doc.data(), id: doc.id }));
    }, [userId]);

    return (
        <UserContext.Provider value={[user, newUser => db.collection("users").doc(userId).update(newUser)]}>
            {children}
        </UserContext.Provider>
    );
};

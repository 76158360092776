import React, { useState, useEffect, createContext, useContext } from "react";
import { db } from "shared/firebase";
import { Pool } from "common/types";
import { getEmptyPool } from "common/utils";

const PoolsContext = createContext<[Pool[] | undefined, ((pool: Partial<Pool>) => void) | undefined]>([
    undefined,
    undefined
]);

export const usePools = () => useContext(PoolsContext);

export const PoolsProvider = ({ children }: { children: JSX.Element }) => {
    const [pools, setPools] = useState<Pool[] | undefined>(undefined);

    useEffect(() => {
        return db
            .collection("pools")
            .orderBy("createdAt", "desc")
            .onSnapshot(snapshot =>
                setPools(snapshot.docs.map(doc => ({ ...getEmptyPool(), ...doc.data(), id: doc.id })))
            );
    }, []);

    return (
        <PoolsContext.Provider value={[pools, newPool => db.collection("pools").doc(newPool.id).update(newPool)]}>
            {children}
        </PoolsContext.Provider>
    );
};

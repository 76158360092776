import React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useCompanies, usePools } from "shared/hooks";
import { useNavigate } from "react-router-dom";

const Explore = () => {
    const navigate = useNavigate();
    const [companies] = useCompanies();
    const [pools] = usePools();

    if (!companies || !pools) return <></>;

    return (
        <div style={{ padding: 24, display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 20 }}>
            {companies
                .filter(c => c.createdAt > 0)
                .sort((a, b) => a.title.localeCompare(b.title))
                .map(company => {
                    const count = pools.filter(pool => pool.companyId === company.id).length;
                    return (
                        <Paper style={{ maxWidth: 240, padding: 24 }} elevation={0}>
                            <img src={company.logoUrl} alt="logo" height={50} />
                            <Typography variant="h6" style={{ fontWeight: 600 }} gutterBottom>
                                {company.title}
                            </Typography>
                            <Typography variant="body2" style={{ color: "#606060" }} gutterBottom>
                                {company.description.length > 100
                                    ? company.description.substring(0, 100) + "..."
                                    : company.description}
                            </Typography>
                            <div style={{ height: 10 }} />
                            <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                disableElevation
                                style={{ borderRadius: 0 }}
                                onClick={() => {
                                    navigate(`/home/company/${company.id}`);
                                }}>
                                {count} pulje{count > 1 ? "r" : ""}
                            </Button>
                        </Paper>
                    );
                })}
        </div>
    );
};

export default Explore;

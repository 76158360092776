import React, { useState, useEffect, createContext, useContext } from "react";
import { db } from "shared/firebase";
import { Question } from "common/types";
import { getEmptyQuestion } from "common/utils";

const QuestionsContext = createContext<[Question[] | undefined, ((question: Partial<Question>) => void) | undefined]>([
    undefined,
    undefined
]);

export const useQuestions = () => useContext(QuestionsContext);

export const QuestionsProvider = ({ children }: { children: JSX.Element }) => {
    const [questions, setQuestions] = useState<Question[] | undefined>(undefined);

    useEffect(() => {
        return db
            .collection("questions")
            .orderBy("createdAt", "desc")
            .onSnapshot(snapshot =>
                setQuestions(snapshot.docs.map(doc => ({ ...getEmptyQuestion(), ...doc.data(), id: doc.id })))
            );
    }, []);

    return (
        <QuestionsContext.Provider
            value={[questions, newQuestion => db.collection("questions").doc(newQuestion.id).update(newQuestion)]}>
            {children}
        </QuestionsContext.Provider>
    );
};

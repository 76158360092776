import React from "react";
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ListItem from "@material-ui/core/ListItem";
import Feed from "./Feed";
import Explore from "./Explore";
import Company from "./Company";
import ExploreOutlinedIcon from "@material-ui/icons/ExploreOutlined";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import HomeIcon from "@material-ui/icons/Home";
import Menu from "./Menu";
import Messages from "./Messages";
import Pool from "./Pool";
import logo from "./origoLogo.png";
import { useUser, useCompanies, usePools } from "shared/hooks";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex"
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1
        },
        appBarShift: {
            marginLeft: drawerWidth,
            width: `100%`
        },
        menuButton: {
            marginRight: 10
        },
        hide: {
            display: "none"
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: "nowrap"
        },
        drawerOpen: {
            width: drawerWidth,
            borderRight: 0
        },
        drawerClose: {
            overflowX: "hidden",
            borderRight: 0,
            width: theme.spacing(7) + 1,
            [theme.breakpoints.up("sm")]: {
                width: theme.spacing(9) + 1
            }
        },
        toolbar: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar
        },
        content: {
            flexGrow: 1
        },
        listItem: {
            paddingLeft: theme.spacing(2),
            alignItems: "center"
        },
        listItemClose: { flexDirection: "column", justifyContent: "center" },
        listItemOpen: { paddingLeft: theme.spacing(3) },
        listItemIcon: { minWidth: 0, marginTop: 4 },
        listItemText: { fontSize: 10 },
        listItemTextOpen: { fontSize: 14, marginLeft: 10 },
        listItemTextClose: { marginTop: -3 }
    })
);

export default function MiniDrawer() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const location = useLocation();
    const userId = new URLSearchParams(location.search).get("userId");
    if (userId) window.localStorage.setItem("userId", userId);
    const navigate = useNavigate();
    const [user] = useUser();
    const [companies] = useCompanies();
    const [pools] = usePools();

    React.useEffect(() => {
        if (location.pathname.substring(0, 10) === "/home/pool") setOpen(false);
    }, [location]);

    if (!user || !companies || !pools) return <></>;

    const poolsObj = Object.fromEntries(pools.map(p => [p.id, p]));

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                elevation={0}
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open
                })}
                color="inherit">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => setOpen(!open)}
                        edge="start"
                        className={clsx(classes.menuButton)}>
                        <MenuIcon />
                    </IconButton>
                    <div style={{ flex: 1, alignItems: "center", display: "flex" }}>
                        <img src={logo} alt="logo" height={30} />
                    </div>
                    <Menu />
                </Toolbar>
            </AppBar>

            <Drawer
                variant={location.pathname.substring(0, 10) === "/home/pool" ? "temporary" : "permanent"}
                open={open}
                ModalProps={{ onBackdropClick: () => setOpen(false) }}
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open
                    })
                }}>
                {location.pathname.substring(0, 10) === "/home/pool" ? (
                    <div style={{ display: "flex", flexDirection: "row", paddingLeft: 24, paddingTop: 8 }}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={() => setOpen(!open)}
                            edge="start"
                            className={clsx(classes.menuButton)}>
                            <MenuIcon />
                        </IconButton>
                        <div style={{ flex: 1, alignItems: "center", display: "flex" }}>
                            <img src={logo} alt="logo" height={30} />
                        </div>
                    </div>
                ) : (
                    <div className={classes.toolbar} />
                )}
                <List>
                    <ListItem
                        button
                        selected={location.pathname === "/home/feed"}
                        onClick={() => navigate("/home/feed")}
                        className={clsx(classes.listItem, {
                            [classes.listItemOpen]: open,
                            [classes.listItemClose]: !open
                        })}>
                        <div className={classes.listItemIcon}>
                            <HomeIcon style={{ color: "#000" }} />
                        </div>
                        {open && <div style={{ width: 18 }} />}
                        <Typography
                            className={clsx(classes.listItemText, {
                                [classes.listItemTextOpen]: open,
                                [classes.listItemTextClose]: !open
                            })}
                            variant={open ? "subtitle2" : "caption"}>
                            Startside
                        </Typography>
                    </ListItem>
                    <ListItem
                        button
                        selected={location.pathname === "/home/explore"}
                        onClick={() => navigate("/home/explore")}
                        className={clsx(classes.listItem, {
                            [classes.listItemOpen]: open,
                            [classes.listItemClose]: !open
                        })}>
                        <div className={classes.listItemIcon}>
                            <ExploreOutlinedIcon style={{ color: "#000" }} />
                        </div>
                        {open && <div style={{ width: 18 }} />}
                        <Typography
                            className={clsx(classes.listItemText, {
                                [classes.listItemTextOpen]: open,
                                [classes.listItemTextClose]: !open
                            })}
                            variant={open ? "subtitle2" : "caption"}>
                            Udforsk
                        </Typography>
                    </ListItem>
                    <ListItem
                        button
                        selected={location.pathname === "/home/messages"}
                        onClick={() => navigate("/home/messages")}
                        className={clsx(classes.listItem, {
                            [classes.listItemOpen]: open,
                            [classes.listItemClose]: !open
                        })}>
                        <div className={classes.listItemIcon}>
                            <MailOutlineIcon style={{ color: "#000" }} />
                        </div>
                        {open && <div style={{ width: 18 }} />}
                        <Typography
                            className={clsx(classes.listItemText, {
                                [classes.listItemTextOpen]: open,
                                [classes.listItemTextClose]: !open
                            })}
                            variant="caption">
                            Jobforslag
                        </Typography>
                    </ListItem>
                </List>
                {open && user.poolIds.length > 0 && (
                    <>
                        <Divider />
                        <div style={{ marginTop: 15, paddingLeft: 25 }}>
                            <Typography variant={"button"} style={{ color: "#606060" }}>
                                Tilknyttet
                            </Typography>
                        </div>
                        {user.poolIds
                            .map(id => poolsObj[id])
                            .map(pool => {
                                const company = companies.find(c => c.id === pool.companyId);
                                return (
                                    <ListItem key={pool.id} button onClick={() => navigate(`/home/pool/${pool.id}`)}>
                                        <div style={{ display: "flex", flexDirection: "row", marginLeft: 5 }}>
                                            <img src={company?.logoUrl} alt="logo" height={32} />
                                            <div style={{ marginLeft: 10 }}>
                                                <Typography variant={"subtitle2"}>{company?.title}</Typography>
                                                <Typography
                                                    variant={"body2"}
                                                    style={{ color: "#606060", fontSize: 10, marginTop: -5 }}>
                                                    {pool.title.length > 25
                                                        ? pool.title.substring(0, 25) + "..."
                                                        : pool.title}
                                                </Typography>
                                            </div>
                                        </div>
                                    </ListItem>
                                );
                            })}
                    </>
                )}
            </Drawer>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Divider />
                <Routes>
                    <Route path={"/feed"} element={<Feed />} />
                    <Route path={"/explore"} element={<Explore />} />
                    <Route path={"/company/:companyId"} element={<Company />} />
                    <Route path={"/pool/:poolId"} element={<Pool />} />
                    <Route path={"/messages"} element={<Messages />} />
                </Routes>
            </main>
        </div>
    );
}

import React, { useState } from "react";
import { Typography, Button, Tabs, Tab, Box } from "@material-ui/core";
import { useNavigate, useParams } from "react-router-dom";
import { useCompanies, usePools } from "shared/hooks";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

export default function SimplePaper() {
    const navigate = useNavigate();
    const { companyId } = useParams();
    const [value, setValue] = useState(0);
    const [companies] = useCompanies();
    const [pools] = usePools();

    const company = (companies || []).find(c => c.id === companyId);

    if (!company || !pools) return <></>;

    const companyPools = pools.filter(p => p.companyId === companyId);

    return (
        <>
            <div style={{ width: "100%", height: 100, backgroundColor: "#ccc" }} />
            <div
                style={{
                    width: "100%",
                    backgroundColor: "#fff",
                    paddingLeft: 50,
                    paddingRight: 50,
                    paddingTop: 24
                }}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 20 }}>
                    <img src={company.logoUrl} alt="logo" height={100} />
                    <Typography variant="h4" style={{ fontWeight: 600, marginLeft: 20 }}>
                        {company.title}
                    </Typography>
                </div>
                <Tabs
                    TabIndicatorProps={{ style: { background: "#000" } }}
                    value={value}
                    indicatorColor="primary"
                    onChange={(_, newValue) => setValue(newValue)}
                    aria-label="disabled tabs example">
                    <Tab label="Puljer" />
                    <Tab label="om" />
                </Tabs>
            </div>
            <TabPanel value={value} index={0}>
                <div
                    style={{
                        paddingLeft: 24,
                        paddingRight: 24,
                        paddingTop: 12
                    }}>
                    {companyPools.map(pool => (
                        <div
                            style={{
                                width: "100%",
                                backgroundColor: "#fff",
                                padding: 36,
                                display: "flex",
                                flexDirection: "row"
                            }}>
                            <div style={{ flex: 1 }}>
                                <Typography variant="h6" style={{ fontWeight: 600 }}>
                                    {pool.title}
                                </Typography>
                            </div>
                            <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                disableElevation
                                style={{ borderRadius: 0 }}
                                onClick={() => {
                                    navigate(`/home/pool/${pool.id}`);
                                }}>
                                se pulje
                            </Button>
                        </div>
                    ))}
                </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <div
                    style={{
                        paddingLeft: 50,
                        paddingRight: 50,
                        paddingTop: 12
                    }}>
                    <Typography variant="h6" style={{ fontWeight: 600 }}>
                        Om {company.title}
                    </Typography>
                    <Typography variant="body1">{company.description}</Typography>
                </div>
            </TabPanel>
        </>
    );
}

import React, { useState } from "react";
import { useUser } from "shared/hooks";
import { withStyles } from "@material-ui/core/styles";
import { Button, Badge, IconButton, Menu, Typography, Divider, TextField } from "@material-ui/core";
import { MenuProps } from "@material-ui/core/Menu";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
const StyledMenu = withStyles({
    paper: {
        border: "1px solid #d3d4d5",
        padding: 8,
        paddingTop: 0,
        width: 300
    }
})((props: MenuProps) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "center"
        }}
        {...props}
    />
));

export default function CustomizedMenus() {
    const [user, setUser] = useUser();
    const [email, setEmail] = useState("");
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    if (!user || !setUser) return <></>;

    return (
        <div>
            <IconButton onClick={e => setAnchorEl(e.currentTarget)}>
                {user.email ? (
                    <div
                        style={{
                            backgroundColor: "#3f51b5",
                            height: 30,
                            width: 30,
                            borderRadius: 25,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                        <p style={{ fontSize: 16, color: "#fff" }}>{user.email[0].toUpperCase()}</p>
                    </div>
                ) : (
                    <Badge badgeContent={"!"} color="secondary">
                        <AccountCircleIcon style={{ fontSize: 30 }} />
                    </Badge>
                )}
            </IconButton>
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}>
                <Typography variant="subtitle2" style={{ fontSize: 16, fontWeight: 600 }}>
                    Konto
                </Typography>
                <Divider style={{ marginBottom: 10 }} />
                {user.email ? (
                    <div style={{ display: "flex", flexDirection: "row" }}>Email: {user.email}</div>
                ) : (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <ErrorOutlineIcon style={{ color: "#FF0000", marginRight: 10 }} />
                        <div>
                            <Typography variant="subtitle2" style={{ fontSize: 14, fontWeight: 600 }} gutterBottom>
                                Tilknyt din mail for at færddiggøre oprettelse af konto
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                Oprettelse sikrer at du altid har adgang til din data
                            </Typography>
                            <TextField
                                id="standard-basic"
                                label="Mail"
                                style={{ width: "100%" }}
                                value={email}
                                onChange={e => {
                                    setEmail(e.target.value);
                                }}
                            />
                            <div style={{ height: 18 }} />
                            <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                disableElevation
                                style={{ borderRadius: 0 }}
                                onClick={() => {
                                    setUser({ email });
                                }}>
                                Tilknyt
                            </Button>
                        </div>
                    </div>
                )}
            </StyledMenu>
        </div>
    );
}

import * as types from "../types";

export const getEmptyUser = (): types.User => ({
    id: "",
    createdAt: Date.now(),
    email: "",
    poolIds: []
});

export const getEmptyCompany = (): types.Company => ({
    id: "",
    createdAt: Date.now(),
    title: "",
    url: "",
    logoUrl: "",
    description: ""
});

export const getEmptyPool = (): types.Pool => ({
    id: "",
    createdAt: Date.now(),
    companyId: "",
    title: ""
});

export const getEmptyQuestion = (): types.Question => ({
    id: "",
    createdAt: Date.now(),
    poolId: "",
    title: "",
    options: []
});

export const getEmptyAnswer = (): types.Answer => ({
    id: "",
    createdAt: Date.now(),
    userId: "",
    questionId: "",
    answers: []
});

export const getEmptyJob = (): types.Job => ({
    id: "",
    createdAt: Date.now(),
    poolId: "",
    userId: "",
    title: "",
    url: "",
    timesClicked: 0
});

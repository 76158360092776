import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/analytics";

import firebase from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyA2kCLO5500Uzz0APE5oMpkBNFZ3nPHPnM",
    authDomain: "origo-center.firebaseapp.com",
    projectId: "origo-center",
    storageBucket: "origo-center.appspot.com",
    messagingSenderId: "55324171924",
    appId: "1:55324171924:web:3b41185e74ebf1481e5e81",
    measurementId: "G-FJRT6RSX8C"
};

if (firebase.apps.length === 0) firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();

export const increment = firebase.firestore.FieldValue.increment(1);

export const useEmulator = (host: string) => {
    db.useEmulator(host, 8080);
};

export const analytics = firebase.analytics();

analytics.logEvent("Load page");

import React, { useState, useEffect, createContext, useContext } from "react";
import { db } from "shared/firebase";
import { Answer } from "common/types";
import { getEmptyAnswer } from "common/utils";

const AnswersContext = createContext<[Answer[] | undefined, ((answer: Partial<Answer>) => void) | undefined]>([
    undefined,
    undefined
]);

export const useAnswers = () => useContext(AnswersContext);

export const AnswersProvider = ({ children }: { children: JSX.Element }) => {
    const [answers, setAnswers] = useState<Answer[] | undefined>(undefined);

    useEffect(() => {
        return db
            .collection("answers")
            .orderBy("createdAt", "desc")
            .onSnapshot(snapshot =>
                setAnswers(snapshot.docs.map(doc => ({ ...getEmptyAnswer(), ...doc.data(), id: doc.id })))
            );
    }, []);

    return (
        <AnswersContext.Provider
            value={[
                answers,
                newAnswer => {
                    if (newAnswer.id) db.collection("answers").doc(newAnswer.id).update(newAnswer);
                    else db.collection("answers").add(newAnswer);
                }
            ]}>
            {children}
        </AnswersContext.Provider>
    );
};

import React, { useState } from "react";
import { useCompanies, usePools, useQuestions } from "shared/hooks";
import { db } from "shared/firebase";
import { Container, TextField, Button } from "@material-ui/core";
import Question from "./Question";

const Tools = () => {
    const [companies] = useCompanies();
    const [pools] = usePools();
    const [questions] = useQuestions();
    const [companyId, setCompanyId] = useState("");
    const [poolId, setPoolId] = useState("");
    const [questionTitle, setQuestionTitle] = useState("");
    const [questionOptions, setQuestionOptions] = useState<string[]>([]);

    if (!companies || !pools || !questions) return <></>;

    const selectedCompany = companies.find(c => c.id === companyId);

    const selectedPool = pools.find(p => p.id === poolId);

    return (
        <div>
            <b>Select company</b>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
                {companies.map(company => (
                    <div
                        onClick={() => {
                            setCompanyId(company.id);
                        }}
                        style={{ backgroundColor: company.id === companyId ? "blue" : "" }}>
                        <div>{company.title}</div>
                        <img src={company.logoUrl} width={64} height={64} alt="" />
                    </div>
                ))}
            </div>
            {selectedCompany && (
                <>
                    <br />
                    <b>Select pool</b>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {pools
                            .filter(p => p.companyId === companyId)
                            .map(pool => (
                                <div
                                    onClick={() => {
                                        setPoolId(pool.id);
                                    }}
                                    style={{ backgroundColor: pool.id === poolId ? "blue" : "" }}>
                                    <div>{pool.title}</div>
                                    <img src={selectedCompany.logoUrl} width={64} height={64} alt="" />
                                </div>
                            ))}
                    </div>
                    {selectedPool && (
                        <>
                            <br />
                            <b>Add question</b>
                            <br />
                            <TextField
                                label="Title"
                                value={questionTitle}
                                onChange={e => {
                                    setQuestionTitle(e.target.value);
                                }}
                            />
                            <br />
                            {[...questionOptions, ""].map((option, i) => (
                                <TextField
                                    label={`Option ${i + 1}`}
                                    value={option}
                                    onChange={e => {
                                        const opt = [...questionOptions, ""];
                                        opt[i] = e.target.value;
                                        setQuestionOptions(opt.filter(o => o !== ""));
                                    }}
                                />
                            ))}
                            <br />
                            <br />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={async () => {
                                    await db.collection("questions").add({
                                        createdAt: Date.now(),
                                        poolId: poolId,
                                        title: questionTitle,
                                        options: questionOptions
                                    });
                                    setQuestionTitle("");
                                    setQuestionOptions([]);
                                }}>
                                Submit
                            </Button>
                            <Container maxWidth="sm">
                                {questions
                                    .filter(question => question.poolId === poolId)
                                    .map(question => (
                                        <div style={{ display: "flex" }}>
                                            <div style={{ flex: 1 }}>
                                                <Question question={question} />
                                            </div>
                                            <div>
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={() => {
                                                        if (!window.confirm(`Delete: ${question.title}`)) return;
                                                        db.collection("questions").doc(question.id).delete();
                                                    }}>
                                                    Delete
                                                </Button>
                                            </div>
                                        </div>
                                    ))}
                            </Container>
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default Tools;

import React from "react";
import { UserProvider } from "./User";
export { useUser } from "./User";
import { UsersProvider } from "./Users";
export { useUsers } from "./Users";
import { CompaniesProvider } from "./Companies";
export { useCompanies } from "./Companies";
import { PoolsProvider } from "./Pools";
export { usePools } from "./Pools";
import { QuestionsProvider } from "./Questions";
export { useQuestions } from "./Questions";
import { AnswersProvider } from "./Answers";
export { useAnswers } from "./Answers";
import { JobsProvider } from "./Jobs";
export { useJobs } from "./Jobs";

const Provider = ({ children }: { children: JSX.Element }) => (
    <UserProvider>
        <UsersProvider>
            <CompaniesProvider>
                <PoolsProvider>
                    <QuestionsProvider>
                        <AnswersProvider>
                            <JobsProvider>{children}</JobsProvider>
                        </AnswersProvider>
                    </QuestionsProvider>
                </PoolsProvider>
            </CompaniesProvider>
        </UsersProvider>
    </UserProvider>
);

export default Provider;

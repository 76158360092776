import React, { useState } from "react";
import { Typography, Button, TextField } from "@material-ui/core";
import { useUser, useCompanies, usePools, useJobs } from "shared/hooks";

const Messages = () => {
    const [user, setUser] = useUser();
    const [email, setEmail] = useState("");
    const [companies] = useCompanies();
    const [pools] = usePools();
    const [jobs, setJob] = useJobs();

    if (!user || !setUser || !companies || !pools || !jobs || !setJob) return <></>;

    const userJobs = jobs.filter(j => j.userId === user.id);

    return (
        <div style={{ padding: 24, display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
            <div style={{ backgroundColor: "#fff", borderRadius: 20, padding: 30, maxWidth: 600 }}>
                {userJobs.length === 0 ? (
                    <Typography variant="h6" style={{ fontWeight: 600 }} gutterBottom>
                        Du har ingen jobforslag endnu...
                    </Typography>
                ) : (
                    userJobs.map(job => {
                        const pool = pools.find(p => p.id === job.poolId);
                        if (!pool) return <></>;
                        const company = companies.find(c => c.id === pool.companyId);
                        if (!company) return <></>;
                        return (
                            <div style={{ backgroundColor: "#fff", marginBottom: 25, padding: 10 }}>
                                <div style={{ display: "flex", flexDirection: "row", height: 40 }}>
                                    <div
                                        style={{
                                            width: 40,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}>
                                        <img src={company.logoUrl} alt="logo" height={"80%"} />
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            marginLeft: 24,
                                            flexDirection: "column",
                                            flex: 1
                                        }}>
                                        <div
                                            style={{
                                                display: "flex",
                                                flex: 1,
                                                justifyContent: "center",
                                                flexDirection: "column"
                                            }}>
                                            <p style={{ fontWeight: 600, fontSize: 15, margin: 0 }}>{company.title}</p>
                                            <p style={{ margin: 0, color: "#606060" }}>{pool.title}</p>
                                        </div>
                                    </div>
                                </div>
                                <Typography variant="h6" style={{ fontWeight: 600 }} gutterBottom>
                                    <a
                                        href={job.url}
                                        target="_blank"
                                        rel="noreferrer"
                                        onClick={() => {
                                            setJob({ ...job, timesClicked: job.timesClicked + 1 });
                                        }}>
                                        {job.title}
                                    </a>
                                </Typography>
                            </div>
                        );
                    })
                )}
                <div style={{ height: 10 }} />
                {user.email ? (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        Når du får nye jobforslag kan du se dem her. Du vil også få besked på {user.email}
                    </div>
                ) : (
                    <>
                        <Typography variant="body1">
                            Tilknyt din mail for at færddiggøre oprettelse af konto. Så får du en mail når du modtager
                            en ny besked.
                        </Typography>
                        <TextField
                            id="standard-basic"
                            label="Mail"
                            style={{ width: "100%" }}
                            value={email}
                            onChange={e => {
                                setEmail(e.target.value);
                            }}
                        />
                        <div style={{ height: 18 }} />
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            disableElevation
                            style={{ borderRadius: 0 }}
                            onClick={() => {
                                setUser({ email });
                            }}>
                            Tilknyt
                        </Button>
                    </>
                )}
            </div>
        </div>
    );
};

export default Messages;

import React, { useState, useEffect, createContext, useContext } from "react";
import { db } from "shared/firebase";
import { Company } from "common/types";
import { getEmptyCompany } from "common/utils";

const CompaniesContext = createContext<[Company[] | undefined, ((company: Partial<Company>) => void) | undefined]>([
    undefined,
    undefined
]);

export const useCompanies = () => useContext(CompaniesContext);

export const CompaniesProvider = ({ children }: { children: JSX.Element }) => {
    const [companies, setCompanies] = useState<Company[] | undefined>(undefined);

    useEffect(() => {
        return db
            .collection("companies")
            .orderBy("createdAt", "desc")
            .onSnapshot(snapshot =>
                setCompanies(snapshot.docs.map(doc => ({ ...getEmptyCompany(), ...doc.data(), id: doc.id })))
            );
    }, []);

    return (
        <CompaniesContext.Provider
            value={[companies, newCompany => db.collection("companies").doc(newCompany.id).update(newCompany)]}>
            {children}
        </CompaniesContext.Provider>
    );
};

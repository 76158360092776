import React, { useState, useEffect, createContext, useContext } from "react";
import { db } from "shared/firebase";
import { Job } from "common/types";
import { getEmptyJob } from "common/utils";

const JobsContext = createContext<[Job[] | undefined, ((job: Partial<Job>) => void) | undefined]>([
    undefined,
    undefined
]);

export const useJobs = () => useContext(JobsContext);

export const JobsProvider = ({ children }: { children: JSX.Element }) => {
    const [jobs, setJobs] = useState<Job[] | undefined>(undefined);

    useEffect(() => {
        return db
            .collection("jobs")
            .orderBy("createdAt", "desc")
            .onSnapshot(snapshot =>
                setJobs(snapshot.docs.map(doc => ({ ...getEmptyJob(), ...doc.data(), id: doc.id })))
            );
    }, []);

    return (
        <JobsContext.Provider value={[jobs, newJob => db.collection("jobs").doc(newJob.id).update(newJob)]}>
            {children}
        </JobsContext.Provider>
    );
};

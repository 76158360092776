import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Tools from "./Tools";
import Provider from "shared/hooks";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

ReactDOM.render(
    <React.StrictMode>
        <Provider>
            <Router>
                <Routes>
                    <Route path="/home/*" element={<App />} />
                    <Route path="/tools/*" element={<Tools />} />
                    <Route path="*" element={<Navigate to="/home/feed" replace />} />
                </Routes>
            </Router>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);

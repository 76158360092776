import React from "react";
import { Container } from "@material-ui/core";
import { useUser, usePools, useQuestions } from "shared/hooks";
import Question from "./Question";

const Feed = () => {
    const [user] = useUser();
    const [pools] = usePools();
    const [questions] = useQuestions();

    if (!user || !pools || !questions) return <></>;

    const userPools = pools.filter(p => user.poolIds.includes(p.id));
    const userPoolIds = userPools.map(p => p.id);

    const userQuestions = questions.filter(q => userPoolIds.includes(q.poolId));

    return (
        <Container maxWidth="sm">
            <div style={{ height: 25 }} />
            {userQuestions.map(question => {
                return <Question key={question.id} question={question} />;
            })}
        </Container>
    );
};

export default Feed;

import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUser, useCompanies, usePools, useQuestions, useAnswers } from "shared/hooks";
import { Button, Container, Typography, TextField } from "@material-ui/core";
import Question from "./Question";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const Pool = () => {
    const navigate = useNavigate();
    const { poolId } = useParams();
    const [email, setEmail] = useState("");
    const [user, setUser] = useUser();
    const [companies] = useCompanies();
    const [pools] = usePools();
    const [questions] = useQuestions();
    const [answers, setAnswer] = useAnswers();

    if (poolId === undefined || !user || !setUser || !answers || !setAnswer) return <></>;

    const pool = (pools || []).find(p => p.id === poolId);
    if (!pool || !questions) return <></>;

    const company = (companies || []).find(c => c.id === pool.companyId);
    if (!company) return <></>;

    const poolQuestions = questions.filter(q => q.poolId === poolId);

    return (
        <Container maxWidth="sm">
            <div style={{ height: 25 }} />
            <div style={{ display: "flex", flexDirection: "row", height: 80 }}>
                <div
                    style={{
                        width: 80,
                        backgroundColor: "#fff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                    <img src={company.logoUrl} alt="logo" height={"70%"} />
                </div>
                <div
                    style={{
                        display: "flex",
                        marginLeft: 24,
                        flexDirection: "column",
                        flex: 1
                    }}>
                    <div style={{ display: "flex", flex: 1, justifyContent: "center", flexDirection: "column" }}>
                        <p style={{ fontWeight: 600, fontSize: 18, margin: 0 }}>{pool.title}</p>
                        <p
                            style={{
                                margin: 0,
                                textDecoration: "underline",
                                color: "rgb(63, 81, 181)",
                                cursor: "pointer"
                            }}
                            onClick={() => navigate(`/home/company/${company.id}`)}>
                            Se virksomhed
                        </p>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Button
                        size="small"
                        variant={user.poolIds.includes(poolId) ? "text" : "contained"}
                        color={user.poolIds.includes(poolId) ? "secondary" : "primary"}
                        disableElevation
                        style={{ borderRadius: 0 }}
                        onClick={() =>
                            setUser({
                                poolIds: user.poolIds.includes(poolId)
                                    ? user.poolIds.filter(id => id !== poolId)
                                    : [...user.poolIds, poolId]
                            })
                        }>
                        {user.poolIds.includes(poolId) ? "Afmeld" : "Tilknyt"}
                    </Button>
                </div>
            </div>
            <div style={{ height: 50 }} />
            {poolQuestions.map(question => {
                return <Question question={question} />;
            })}
            <div style={{ display: "flex", flexDirection: "row", marginBottom: 20 }}>
                <div style={{ flex: 1 }} />
                <div style={{ flex: 1, fontSize: 14, color: "#606060" }}>Alle dine besvarelser er gemt og sendt</div>
                <CheckCircleIcon fontSize="small" style={{ color: "green" }} />
            </div>
            {!user.email && (
                <div style={{ backgroundColor: "#fff", borderRadius: 10, padding: 20 }}>
                    <Typography variant="subtitle1" style={{ fontWeight: 600 }} gutterBottom>
                        Vil du modtage en mail hvis {company.title} kontakter dig?
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Tilknyt din mail for at færddiggøre oprettelse af konto. Så får du en mail når du modtager en ny
                        besked.
                    </Typography>
                    <TextField
                        id="standard-basic"
                        label="Mail"
                        style={{ width: "100%" }}
                        value={email}
                        onChange={e => {
                            setEmail(e.target.value);
                        }}
                    />
                    <div style={{ height: 18 }} />
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            disableElevation
                            style={{ borderRadius: 0 }}
                            onClick={() => {
                                setUser({ email });
                                navigate("/home/messages");
                            }}>
                            Tilknyt
                        </Button>
                        <Button
                            size="small"
                            variant="text"
                            color="primary"
                            disableElevation
                            style={{ borderRadius: 0, marginLeft: 10 }}
                            onClick={() => {
                                navigate(`/home/messages`);
                            }}>
                            Gå til jobforslag
                        </Button>
                    </div>
                    <div style={{ height: 18 }} />
                </div>
            )}
        </Container>
    );
};

export default Pool;

import React, { useState, useEffect, createContext, useContext } from "react";
import { db } from "shared/firebase";
import { User } from "common/types";
import { getEmptyUser } from "common/utils";

const UsersContext = createContext<[User[] | undefined, ((user: Partial<User>) => void) | undefined]>([
    undefined,
    undefined
]);

export const useUsers = () => useContext(UsersContext);

export const UsersProvider = ({ children }: { children: JSX.Element }) => {
    const [users, setUsers] = useState<User[] | undefined>(undefined);

    useEffect(() => {
        return db
            .collection("users")
            .onSnapshot(snapshot =>
                setUsers(snapshot.docs.map(doc => ({ ...getEmptyUser(), ...doc.data(), id: doc.id })))
            );
    }, []);

    return (
        <UsersContext.Provider value={[users, newUser => db.collection("users").doc(newUser.id).update(newUser)]}>
            {children}
        </UsersContext.Provider>
    );
};
